/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const News: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path fillRule='evenodd' clipRule='evenodd' d='M17.4 21C19.3882 21 21 19.3882 21 17.4L21 6.6C21 4.61177 19.3882 3 17.4 3L10.2 3C8.52255 3 7.11306 4.1473 6.71342 5.7C6.70416 5.73596 6.69545 5.77213 6.68729 5.80851C6.69545 5.77213 6.70416 5.73596 6.71341 5.7H6.6C4.61177 5.7 3 7.31178 3 9.3L3 17.4C3 19.3882 4.61177 21 6.6 21L10.2 21L17.4 21ZM5.96906 19.0863C6.36881 18.5038 6.6 17.8005 6.6 17.0503L6.6 6.6L6.6 7.5C5.60589 7.5 4.8 8.30589 4.8 9.3L4.8 17.4C4.8 18.1721 5.28615 18.8307 5.96906 19.0863ZM17.4 4.8L10.2 4.8C9.20589 4.8 8.4 5.60589 8.4 6.6L8.4 17.0503C8.4 17.8104 8.2418 18.5383 7.95436 19.2L17.4 19.2C18.3941 19.2 19.2 18.3941 19.2 17.4L19.2 6.6C19.2 5.60589 18.3941 4.8 17.4 4.8Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M9.65002 8.40002C9.65002 7.84774 10.0977 7.40002 10.65 7.40002L16.95 7.40002C17.5023 7.40002 17.95 7.84774 17.95 8.40002C17.95 8.95231 17.5023 9.40002 16.95 9.40002L10.65 9.40002C10.0977 9.40002 9.65002 8.95231 9.65002 8.40002Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M9.65002 12C9.65002 11.4477 10.0977 11 10.65 11L16.95 11C17.5023 11 17.95 11.4477 17.95 12C17.95 12.5523 17.5023 13 16.95 13L10.65 13C10.0977 13 9.65002 12.5523 9.65002 12Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M9.65002 15.6C9.65002 15.0477 10.0977 14.6 10.65 14.6L16.95 14.6C17.5023 14.6 17.95 15.0477 17.95 15.6C17.95 16.1523 17.5023 16.6 16.95 16.6H10.65C10.0977 16.6 9.65002 16.1523 9.65002 15.6Z' fill='currentColor' />
        </svg>;
};

export default News;